<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">キャストランキングの設定</h1>
        <banner-hint>
          ホーム画面管理のランキングパーツを使って1位〜4位までの人気キャストランキングを表示できます。<br />
          キャストをピンポイントで目立せることができるので特定キャストの集客数を増やしたい時などに便利です。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- 登録ボタン -->
        <div class="mb-7">
          <v-btn
            :disabled="!changedRanking"
            depressed
            color="primary"
            @click="registerRanking()"
          >ランキングを登録
          </v-btn>
          <v-btn
            class="ml-3"
            depressed
            color="primary"
            @click="clearRanking()"
          >ランキングを解除
          </v-btn>
        </div>

        <v-card
          class="mb-5 pt-7 pb-5 px-6"
          elevation="1"
        >
          <v-row v-if="!casts.length">
            <v-col cols="12">
              <v-card flat>
                <v-card-text>
                  「店舗管理」→「キャスト管理」からキャストを登録してください。
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- キャストリスト -->
          <v-row>
            <v-col cols="3"
              v-for="cast in casts"
              :key="cast.cast_id"
            >
              <v-card class="cast-card pb-2 mx-auto d-flex flex-column transition-swing"
                color="primary"
                @click="setRanking(cast)"
              >
                <!-- 画像 -->
                <v-card
                  class="hero mb-1"
                  tile flat
                >
                  <!-- ランキング -->
                  <v-sheet
                    class="ranking-icon d-flex flex-column"
                    color="transparent"
                    v-if="cast.ranking"
                  >
                    <v-icon
                      x-large
                      color="accent"
                    >{{ rankingIcon(cast.ranking) }}</v-icon>
                  </v-sheet>

                  <v-img
                    :src="cast.image_url"
                    min-height="230"
                    max-height="280"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="accent"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>

                <!-- 名前 -->
                <v-card-title class="align-self-center pa-0 text-body-1 font-weight-bold">
                  {{ cast.name }}
                </v-card-title>

                <!-- 出勤 -->
                <v-card-title class="shift align-self-center pa-0 text-body-1 font-weight-bold">
                  <span>
                    <v-icon small>mdi-clock-outline</v-icon>
                    {{ displayShift(cast) }}
                  </span>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- 確認モーダル -->
    <modal-confirm ref="modalConfirm">
      <div v-html="modalMessage"></div>
    </modal-confirm>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import $literals from '@/literals.js'
import { SITE_API_ENDPOINT } from '@/literals.js'
import { BizHour, ApiTool, CheckTokenError } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue";
import ModalConfirm from '@/components/_ModalConfirm.vue'

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
    'modal-confirm': ModalConfirm,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      casts: [],
      rankings: [],
      baseRankings: [1, 2, 3, 4],
      changedRanking: false,
      modalMessage: '',
      loading: false,
      loadingMessage: '',
      snackbar: {open: false, color: 'primary', message: ''},
      bizHour: new BizHour(this.shopData),
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      publicApi: new ApiTool(SITE_API_ENDPOINT + '/', this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    dateToday() {
      return moment(this.bizHour.getBizOpening(new Date())).format('YYYYMMDD')
    },
    extractTime() {
      return datime => moment(datime).format('HH:mm')
    },
    displayShift() {
      return castData => castData.start_at ? this.extractTime(castData.start_at) + '〜' + this.extractTime(castData.end_at) : '本日出勤無し'
    },
    rankingIcon() {
      return ranking => 'mdi-numeric-' + ranking + '-circle'
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.changedRanking) {
      this.modalMessage = '<p>変更を登録してない様ですがページを移動してよろしいですか？</p>'

      const modalHanddown = {
        yesCallback: next,
        buttonLabel: '移動する',
      }
      this.$refs.modalConfirm.open(modalHanddown)
    } else {
      next()
    }
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.setCastData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // API req: GET
    //**************************************************
    async setCastData() {
      const shifts = []

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await Promise.all([
        this.publicApi.getReqSitePublic('shift/date/', {date: this.dateToday}).then(shiftRecords => {
          if (!shiftRecords || !shiftRecords.length) return
          shifts.push(...shiftRecords)
        }),

        this.publicApi.getReqSitePublic('common/cast-ranking/').then(rakingRecords => {
          if (!rakingRecords || !rakingRecords.length) return
          this.rankings.push(...rakingRecords)
        }),
      ])

      await this.adminApi.getReqWithAuth('cast/').then( records => {
        if (!records || !records.length) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoCastsExists, open: true}}
          return
        }

        //取得したシフト&ランキングをキャストデータに紐付け
        records.map( cast => {
          cast.ranking = 0
          if (cast.is_active) {
            shifts.map( shift => {
              if (cast.cast_id === shift.cast_id) {
                cast.start_at = shift.start_at
                cast.end_at = shift.end_at
              }
            })

            this.rankings.map( ranking => {
              if (cast.cast_id === ranking.cast_id) {
                cast.ranking = ranking.ranking
              }
            })

            this.casts.push({...cast})
          }
        })
      })

      this.loading = false
    },

    //**************************************************
    //ランキングセット
    //**************************************************
    setRanking(castData) {
      if (castData.ranking > 0) {
        //ランキング解除
        castData.ranking = 0
        this.rankings = this.rankings.filter( ranking => castData.cast_id !== ranking.cast_id )
      }
      else if (this.rankings.length >= this.baseRankings.length) {
        //既にランキング埋まってる
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoAllRankingsOcupied, open: true}}
      }
      else {
        //空きランキングセット
        this.baseRankings.map( baseR => {
          const result = this.rankings.findIndex( r => r.ranking === baseR )
          if (castData.ranking === 0 && result < 0) {
            castData.ranking = baseR
            this.rankings.push({cast_id: castData.cast_id, ranking: baseR})
          }
        })
      }
      this.changedRanking = true
    },

    //**************************************************
    //ランキング登録
    //**************************************************
    registerRanking() {
      if (!this.rankings.length) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningNoRankingData, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const payload = JSON.stringify(this.rankings)

      this.adminApi.apiReqWithData('PUT', 'update/site-cast-ranking/', payload).then(() => {
        this.changedRanking = false
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //ランキングクリア
    //**************************************************
    clearRanking() {
      this.rankings.length = 0
      this.casts.map( cast => cast.ranking = 0 )
    },
  }
}
</script>

<style scoped>
.cast-card {
  overflow: hidden;
}
.hero {
  position: relative;
}
.ranking-icon {
  position: absolute;
  top:5px;
  left: 5px;
  z-index: 1;
}
</style>
